<template>
  <div v-loading="loading" class="container">
    <div class="operate">
      <div class="title-content">
        <div class="title">
          {{ $route.query.name }}-{{ subjectName }}等级成绩榜
        </div>
        <a-tooltip placement="top">
          <template slot="title">
            <span>采用{{ dataType === 2 ? "赋分" : "原始分" }}数据源统计</span>
          </template>
          <a-radio-group
            v-if="
              scoreType == 1 &&
              $route.query.ruleId > 0 &&
              ![1, 2, 3, 4, 8].includes(subjectId)
            "
            v-model="dataType"
            button-style="solid"
            size="small"
            @change="dataTypeChange"
          >
            <a-radio-button :value="1"> 原始分 </a-radio-button>
            <a-radio-button :value="2"> 赋分 </a-radio-button>
          </a-radio-group>
        </a-tooltip>
      </div>
      <div class="btns">
        <a-space>
          <a-button type="primary" ghost @click="downloadFile"
            >下载{{ subjectName }}等级榜
          </a-button>
        </a-space>
      </div>
    </div>
    <div class="filter">
      <div class="filter-item">
        <a-select
          v-model="schoolId"
          style="width: 220px"
          @change="handleChange"
        >
          <a-select-option
            v-for="(item, index) in schoolList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <subjectList
        ref="subjectList"
        v-model="subjectId"
        :has-total="true"
        has-level-segment
        @change="subjectChange"
      />
    </div>

    <a-tabs v-model="type" type="card" @change="tabsChange">
      <a-tab-pane key="1" tab="年级等级榜"> </a-tab-pane>
    </a-tabs>
    <a-table
      bordered
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      :pagination="{
        total: total,
        pageSize: search.size,
        current: search.current,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total}条`,
      }"
      :scroll="{ x: 'max-content', y: tableX }"
      @change="tablePaginationChange"
    ></a-table>
  </div>
</template>

<script>
import subjectList from "../../../components/subjectList.vue";
import { mapState } from "vuex";
import { gradelevellist } from "@/core/api/academic/unionSheet";
import { getstatschooloption } from "@/core/api/academic/common";
import { gradelevellistExport } from "@/core/api/academic/unionSheetExport";
export default {
  name: "",
  components: {
    subjectList,
  },
  data() {
    return {
      tableData: [],
      type: "1",
      loading: false,
      btnLoading: false,
      dataType: 1,
      columns: [],
      subjectId: 1,
      options: null,
      subjectList: [],
      subjectName: "",
      tableX: window.innerHeight - 300,
      schoolId: null,
      total: 0,
      search: {
        size: 20,
        current: 1,
      },
    };
  },
  computed: {
    ...mapState({
      scoreType: (state) => state.report.scoreType,
    }),
  },
  created() {},
  methods: {
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
      this.tabsChange();
    },
    async downloadFile() {
      let res;
      try {
        this.btnLoading = true;
        res = await gradelevellistExport({
          subjectId: this.subjectId,
          statId: this.$route.query.id,
          schoolId: this.schoolId,
          ...this.search,

          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
        });

        const { name, url } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    subjectChange() {
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataType = 1;
      }
      let subject = this.$refs.subjectList.subjectList.find(
        (item) => item.id == this.subjectId
      );
      this.subjectName = subject.name;
      this.getSchoolList();
    },
    handleChange() {
      this.tabsChange();
    },
    async getSchoolList() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.schoolList = res.data.data;
      if (res.data.data.length) {
        this.schoolId = res.data.data[0].id;
        this.tabsChange();
      }
    },
    dataTypeChange() {
      this.tabsChange();
    },
    async getKey1Data() {
      try {
        this.loading = true;
        let res;
        res = await gradelevellist({
          subjectId: this.subjectId,
          statId: this.$route.query.id,
          schoolId: this.schoolId,
          ...this.search,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
        });

        let cols = [];
        if (res.data.data.records.length) {
          cols = res.data.data.records[0].subjects;
        }
        let normalCol = [
          {
            dataIndex: "schoolName",
            title: "学校",
            align: "center",
            width: 120,
          },
          {
            dataIndex: "studentNo",
            title: "学籍号",
            width: 110,
            align: "center",
          },
          {
            dataIndex: "studentExamId",
            title: "考号",
            align: "center",
            width: 110,
          },
          {
            dataIndex: "classNum",
            title: "行政班级",
            align: "center",
            width: 75,
          },
          {
            dataIndex: "eleccomb",
            title: "选考组合",
            align: "center",
            width: 75,
          },
          {
            dataIndex: "studentName",
            title: "姓名",
            align: "center",
            width: 90,
          },
        ];
        if (this.scoreType != 1 || this.$route.query.ruleId === 0) {
          normalCol = normalCol.filter((item) => item.title !== "选考组合");
        }
        if (this.subjectId == 0) {
          this.columns = [
            ...normalCol,
            ...cols.map((item, index) => ({
              dataIndex: "level" + index,
              title: item.subjectName,
              align: "center",
              width: 60,
              customRender: (text, record) => record.subjects[index].level,
            })),
          ];
        } else {
          this.columns = [
            ...normalCol,
            {
              dataIndex: "level",
              title: "等级",
              align: "center",
              width: 45,
            },
          ];
        }
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    tabsChange() {
      const enmu = {
        1: this.getKey1Data,
      };
      this.options = null;
      enmu[this.type]();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .title-content {
      display: flex;
      align-items: center;
      .title {
        font-size: 14px;
        color: #0a1119;
        font-weight: 600;
        margin-right: 8px;
      }
    }
  }
}
::v-deep .ant-tabs-bar {
  margin: 0 0 0 0 !important;
}
::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 6px !important;
}
::v-deep .ant-table-row-cell-break-word {
  padding: 4px 6px !important;
}
.line-chart-title {
  margin: 18px 0;
  color: #0a1119;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.charts-box {
  width: 100%;
  .charts {
    width: 100%;
    height: 650px;
  }
}
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .filter-item {
    margin-bottom: 18px;
    margin-right: 18px;
  }
}
::v-deep .ant-table-pagination.ant-pagination {
  float: right;
  margin: 10px 0;
}
</style>
