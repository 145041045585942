var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"container"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.query.name)+"-"+_vm._s(_vm.subjectName)+"等级成绩榜 ")]),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("采用"+_vm._s(_vm.dataType === 2 ? "赋分" : "原始分")+"数据源统计")])]),(
            _vm.scoreType == 1 &&
            _vm.$route.query.ruleId > 0 &&
            ![1, 2, 3, 4, 8].includes(_vm.subjectId)
          )?_c('a-radio-group',{attrs:{"button-style":"solid","size":"small"},on:{"change":_vm.dataTypeChange},model:{value:(_vm.dataType),callback:function ($$v) {_vm.dataType=$$v},expression:"dataType"}},[_c('a-radio-button',{attrs:{"value":1}},[_vm._v(" 原始分 ")]),_c('a-radio-button',{attrs:{"value":2}},[_vm._v(" 赋分 ")])],1):_vm._e()],2)],1),_c('div',{staticClass:"btns"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":_vm.downloadFile}},[_vm._v("下载"+_vm._s(_vm.subjectName)+"等级榜 ")])],1)],1)]),_c('div',{staticClass:"filter"},[_c('div',{staticClass:"filter-item"},[_c('a-select',{staticStyle:{"width":"220px"},on:{"change":_vm.handleChange},model:{value:(_vm.schoolId),callback:function ($$v) {_vm.schoolId=$$v},expression:"schoolId"}},_vm._l((_vm.schoolList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('subjectList',{ref:"subjectList",attrs:{"has-total":true,"has-level-segment":""},on:{"change":_vm.subjectChange},model:{value:(_vm.subjectId),callback:function ($$v) {_vm.subjectId=$$v},expression:"subjectId"}})],1),_c('a-tabs',{attrs:{"type":"card"},on:{"change":_vm.tabsChange},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"年级等级榜"}})],1),_c('a-table',{attrs:{"bordered":"","row-key":(record) => record.id,"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
      total: _vm.total,
      pageSize: _vm.search.size,
      current: _vm.search.current,
      showSizeChanger: true,
      showTotal: (total) => `共 ${total}条`,
    },"scroll":{ x: 'max-content', y: _vm.tableX }},on:{"change":_vm.tablePaginationChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }